var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.open)?_c('div',{staticClass:"order-details-modal-container animation-open-modal"},[_c('header',[_c('div',{staticClass:"header-container"},[_c('div',{staticClass:"header-container-left"},[_c('div',{on:{"click":_vm.backToOrderList}},[_vm._t("back-button")],2),_c('h1',[_vm._v(_vm._s(((_vm.orderData.order_no) + "/" + (_vm.orderData.op_no))))])]),_c('button',{on:{"click":_vm.closeModal}},[(_vm.windowWidth > 425 && _vm.windowWidth < 768)?_c('CloseIcon'):_c('CloseIconSmall')],1)]),_c('div',{staticClass:"graph-buttons-resources"},[_c('button',{class:[
                'btn-graph-resources',
                _vm.showInformation
                    ? 'btn-active'
                    : 'btn-disabled' ],attrs:{"type":"button"},on:{"click":function () { _vm.showInformation = true; _vm.showProgress = false; _vm.showProductionProgress = false; _vm.showLinks = false; }}},[_vm._v(" "+_vm._s(_vm.$t('Informations'))+" ")]),_c('button',{class:[
                'btn-graph-resources',
                _vm.showProgress
                    ? 'btn-active'
                    : 'btn-disabled' ],attrs:{"type":"button"},on:{"click":function () { _vm.showProgress = true; _vm.showInformation = false; _vm.showProductionProgress = false; _vm.showLinks = false; }}},[_vm._v(" "+_vm._s(_vm.$t('Progress'))+" ")]),_c('button',{class:[
                'btn-graph-resources',
                _vm.showProductionProgress
                    ? 'btn-active'
                    : 'btn-disabled' ],attrs:{"type":"button"},on:{"click":function () { _vm.showProductionProgress = true; _vm.showProgress = false; _vm.showInformation = false; _vm.showLinks = false; }}},[_vm._v(" "+_vm._s(_vm.$t('Production'))+" ")]),_c('button',{class:[
                'btn-graph-resources',
                _vm.showLinks
                    ? 'btn-active'
                    : 'btn-disabled' ],attrs:{"type":"button"},on:{"click":function () { _vm.showLinks = true; _vm.showProductionProgress = false; _vm.showProgress = false; _vm.showInformation = false }}},[_vm._v(" "+_vm._s(_vm.$t('Links'))+" ")])])]),_c('main',[(_vm.showInformation)?_c('InfoTab',{attrs:{"data":_vm.componentsProps.infoTab}}):_vm._e(),(_vm.showProgress)?_c('ProgressTab',{attrs:{"data":_vm.componentsProps.progressTab}}):_vm._e(),_c('TabLinks',{directives:[{name:"show",rawName:"v-show",value:(_vm.showLinks),expression:"showLinks"}],attrs:{"list":_vm.linksList},on:{"update:list":function($event){_vm.linksList=$event}}}),(_vm.showProductionProgress)?_c('TabNote',{attrs:{"list":_vm.listOperationProgress,"isMultipleOrders":_vm.isMultipleOrders,"isControlByHour":_vm.isControlByHour},on:{"deleteOperationProgress":function($event){return _vm.handleAfterDeleteAppointment()}}}):_vm._e()],1)]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }